import {
  isDynamicRoute,
  isRedirectedRoute,
} from '../../../pages/[[...dynamicRoutes]]';

function urlExists(pathname) {
  if (!pathname.includes('/[[...dynamicRoutes]]')) {
    return true;
  }
  if (
    pathname.includes('/[[...dynamicRoutes]]') &&
    (isDynamicRoute(pathname) || isRedirectedRoute(pathname))
  ) {
    return true;
  }
  return false;
}

export default urlExists;
