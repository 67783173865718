import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { SplashScreen } from '../features/auth/ui/common/SplashScreen';
import { urls } from '../features/libs/urls';
import { useUser } from '../features/shared/hooks/useUser';

function sanitizeUrl(url: string) {
  let sanitizedUrl = url;
  if (url[0] === '/') sanitizedUrl = url.slice(1, url.length);
  if (url[url.length - 1] === '/')
    sanitizedUrl = sanitizedUrl.slice(0, sanitizedUrl.length - 1);
  return sanitizedUrl;
}

const dynamicRoutes = [
  ['orders', Number],
  ['documents', Number, 'sign'],
  ['finance', String],
  ['paymenthub', String],
  ['paymenthub', String, Number],
  ['payrolls', Number],
  ['resetpassword', String],
  ['impersonate', String],
];

enum RedirectedRoute {
  '/start/login/' = '/start/login/',
  '/start/register/' = '/start/register/',
  '/auth/welcome/' = '/auth/welcome/',
}
export const isRedirectedRoute = (pathname: string) =>
  pathname in RedirectedRoute;

export function isDynamicRoute(rawUrl: string) {
  if (rawUrl === '/') return true;
  const sanitizedUrl = sanitizeUrl(rawUrl);
  const url = sanitizedUrl.split('/');
  return dynamicRoutes.some(route => {
    if (url.length !== route.length) return false;
    return route.every((element, index) => {
      if (url[index] === element) return true;
      if (element === String && url[index] !== '') return true;
      if (Number === element && Number(url[index]) !== null) return true;
      return false;
    });
  });
}

function Index() {
  const router = useRouter();
  const user = useUser();

  const routesRedirection: { [url in RedirectedRoute]: string } = {
    '/start/login/': user.status.isAuthenticated ? urls.home : urls.login,
    '/start/register/': user.status.isAuthenticated ? urls.home : urls.register,
    '/auth/welcome/': urls.welcome,
  };

  useEffect(() => {
    const asPath = router.asPath.split('?');
    const pathname = asPath[0];
    const query = asPath.length > 1 ? asPath[1] : {};
    if (!pathname.includes('dynamicRoutes')) {
      if (isDynamicRoute(pathname)) {
        if (pathname === '/') {
          if (user.status.isAuthenticated) {
            router.replace(urls.home);
          } else {
            router.push(urls.login);
          }
        } else router.push({ pathname, query });
      } else if (isRedirectedRoute(pathname)) {
        router.push({ pathname: routesRedirection[pathname], query });
      } else if (router.isReady) router.push(urls.notFound);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  return <SplashScreen />;
}

export default Index;
