import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import { createWrapper } from 'next-redux-wrapper';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

import rootReducer from '../store/reducers/rootReducer.ts';
import wsMiddleware from '../config/middleware/websocketMiddleware';
import tracker from '../helpers/tracker';
import migrations from './migration';

// import logger from "redux-logger";

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

let middleware = [];
if (
  (process.env.NODE_ENV !== 'production' ||
    process.env.REACT_APP_CUSTOM_NODE_ENV === 'staging') &&
  process.env.NEXT_REDUX_LOGGER
) {
  // eslint-disable-next-line global-require
  const logger = require('redux-logger').createLogger();
  middleware = [logger];
}

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  applyMiddleware(tracker.middleware),
  applyMiddleware(wsMiddleware),
  applyMiddleware(...middleware)
);

const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};

const storage =
  typeof window !== 'undefined'
    ? createWebStorage('local')
    : createNoopStorage();

const persistConfig = {
  key: 'root',
  storage,
  version: 4,
  whitelist: ['authState', 'uiState', 'paymentsState', 'creditLineState'],
  timeout: null,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store);
const wrapper = createWrapper(() => store);

export { store, persistor, wrapper };
