import { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import * as snippet from '@segment/snippet';
import {
  createTheme,
  CssBaseline,
  makeStyles,
  MuiThemeProvider,
} from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith/isomorphic';
import { IState } from 'flagsmith/types';
import { SnackbarProvider } from 'notistack';
import { persistor, store, wrapper } from '../src/modules/store';
import '../src/index.css';
import { theme } from '../src/design-system/theme';
import tracker from '../src/helpers/tracker';
import { RouterProxy } from '../features/routerProxy/infra/RouterProxy';
import { theme as saasTheme } from '../features/auth/ui/common/theme/theme';
import Notifier from '../src/components/Notifier';
import WebsocketManager from '../src/config/websocket/components/WebsocketManager';
import '../src/i18n';

function MyApp({
  Component,
  pageProps,
}: AppProps & { flagsmithState: IState }) {
  const [isFlagsmithInitialized, setIsFlagsmithInitialized] = useState(false);
  const appTheme = createTheme(theme);
  const pageview = url => {
    (window as any).dataLayer.push({
      event: 'pageview',
      page: url,
    });
  };

  const router = useRouter();

  const originUrl = typeof window !== 'undefined' && window.location.origin;
  const canonicalUrl = originUrl + router.asPath.split('?')[0];

  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  const snackbarProviderClasses = makeStyles({
    success: { backgroundColor: `${theme.palette.success.main} !important` },
    error: { backgroundColor: `${theme.palette.error.main} !important` },
    warning: { backgroundColor: `${theme.palette.warning.main} !important` },
    info: { backgroundColor: `${theme.palette.info.main} !important` },
  })();

  const saasPages = new Set([
    'auth',
    'login',
    'register',
    'forgot-password',
    'home',
    'legal-representatives',
    '404',
  ]);

  const queryClient = new QueryClient();

  useEffect(() => {
    async function fetchFlags() {
      if (!isFlagsmithInitialized) {
        await flagsmith.init({
          environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_API_KEY,
          cacheFlags: true,
        });

        setIsFlagsmithInitialized(true);
      }
    }

    fetchFlags();
  }, [isFlagsmithInitialized]);

  return (
    <>
      <Head>
        <title>Xepelin</title>
        <meta name="robots" content="index,nofollow" />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <Script
        id="segment-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{ __html: snippet.min() }}
      />
      <Script
        id="intercom-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            function l() {
              var s = d.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://widget.intercom.io/widget/etu1apbj';
              var x = d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            }
            if (w.attachEvent) {
              w.attachEvent('onload', l);
            } else {
              w.addEventListener('load', l, false);
            }
          }
        `,
        }}
      />
      {/* Google Tag Manager */}
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY}');
          `,
        }}
      />
      <MuiThemeProvider
        theme={
          saasPages.has(router.pathname.split('/')[1]) ? saasTheme : appTheme
        }
      >
        <PersistGate loading={null} persistor={persistor}>
          <FlagsmithProvider flagsmith={flagsmith}>
            <SnackbarProvider
              classes={{
                variantSuccess: snackbarProviderClasses.success,
                variantError: snackbarProviderClasses.error,
                variantWarning: snackbarProviderClasses.warning,
                variantInfo: snackbarProviderClasses.info,
              }}
              maxSnack={4}
            >
              <RouterProxy isFlagsmithInitialized={isFlagsmithInitialized}>
                <QueryClientProvider client={queryClient}>
                  <>
                    <Notifier />
                    <WebsocketManager />
                    <tracker.NextTrackerComponent store={store} />
                    <CssBaseline />
                    <Component {...pageProps} />
                  </>
                </QueryClientProvider>
              </RouterProxy>
            </SnackbarProvider>
          </FlagsmithProvider>
        </PersistGate>
      </MuiThemeProvider>
    </>
  );
}

export default wrapper.withRedux(MyApp);
